import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../Layout/SharedStyles/Container';
import Accordion from '../Accordion/Accordion';
import Button from '../Button/Button';
import IconEnergy from '../Layout/Icons/IconEnergy';
import IconLighting from '../Layout/Icons/IconLighting';
import IconWater from '../Layout/Icons/IconWater';
import IconExperience from '../Layout/Icons/IconExperience';
import IconPower from '../Layout/Icons/IconPower';
import IconDesign from '../Layout/Icons/IconDesign';
import style from './product-specs.mod.scss';

const ProductSpecs = ({
  image,
  length,
  width,
  pdfLink,
  items,
  warrantyPdfLink,
}) => {
  const getIcon = (icon) => {
    switch (icon) {
      case 'Design':
        return <IconDesign />;
      case 'Energy':
        return <IconEnergy />;
      case 'Lighting':
        return <IconLighting />;
      case 'Water':
        return <IconWater />;
      case 'Experience':
        return <IconExperience />;
      case 'Power':
        return <IconPower />;
      default:
        <IconDesign />;
    }
  };

  return (
    <div id={'product-specs'}>
      <Container width="small" pt={2} pb={2}>
        <div className={style.specs}>
          <h2 className={style.specs__heading}>Specifications</h2>
          <div className={style.specs__image}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
            <div className={style.specs__image__width}>
              <span></span>
              <>{width}</>
              <span></span>
            </div>
            <span className={style.specs__image__length}>
              <span></span>
              <>{length}</>
              <span></span>
            </span>
          </div>
          <div className={style.specs__list}>
            {items.map((item, i) => {
              const selectedIcon = getIcon(item.icon);
              return (
                <Accordion
                  key={item.id}
                  label={item.heading}
                  items={item.details}
                  icon={selectedIcon}
                  isDefaultOpen={i === 0 ? true : false}
                />
              );
            })}
          </div>

          <div className={style.specs__pdfs}>
            {pdfLink && <Button to={pdfLink}>Download Specs</Button>}
            {warrantyPdfLink && (
              <Button to={warrantyPdfLink}>Warranty Sheet</Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductSpecs;
