import React from 'react';

const IconExperience = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height={20}
      width={20}
    >
      <circle id="Oval" cx="20" cy="20" r="20" fill="#3095b4" />
      <path
        id="iconmonstr-view-17"
        fill="#fff"
        d="M12.92 0 1 6.5v13.14L12.92 26l11.91-6.36V6.57L12.92 0Zm8.57 7.2-8.57 4.57L4.4 7.12l8.52-4.65ZM3.17 8.92l8.66 4.72v9.33l-8.66-4.63V8.92ZM14 22.97v-9.32l8.67-4.63v9.32Z"
        transform="translate(7 6.76)"
      />
    </svg>
  );
};

export default IconExperience;
