import React from 'react';

const IconEnergy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height={20}
      width={20}
    >
      <circle id="Oval" cx="20" cy="20" r="20" fill="#3095b4" />
      <path
        id="iconmonstr-connection-4"
        fill="#fff"
        d="m12.57 18.76 1.35-4.19h-4.5l6.9-7.33-1.44 4.19h3.98ZM14.14 2a10.99 10.99 0 0 0-10.87 9.43H0l4.19 6.2 4.19-6.2H5.4a8.9 8.9 0 1 1 1.23 6.33l-1.26 1.86A11 11 0 1 0 14.14 2Z"
        transform="translate(6 7.42)"
      />
    </svg>
  );
};

export default IconEnergy;
