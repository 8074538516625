import React from 'react';
import style from './product-colors.mod.scss';
import Viewer from '../Viewer/viewer';
import Container from '../Layout/SharedStyles/Container';

const ProductColors = ({ variants, comms, integration }) => {
  return (
    <div className={style.colors} id={'product-colors'}>
      {/* <div className={style.colors} id={'product-colors'}>
        <h2>ProductColors</h2>
      </div> */}
      <Container width="small" pt={2} pb={1}>
        <h2>Colours to Suit Your Home</h2>
        <p>
          Configure your item. Check out the colour options or use our augmented
          reality platform to view this product virtually at your home.
        </p>
      </Container>
      <Viewer items={variants} comms={comms} integration={integration} />
    </div>
  );
};

export default ProductColors;
