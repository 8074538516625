import React from 'react';

const IconDesign = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height={20}
      width={20}
    >
      <circle id="Oval" cx="20" cy="20" r="20" fill="#3095b4" />
      <path
        id="iconmonstr-ruler-30"
        fill="#fff"
        d="M5.17 20.69 0 22l1.28-5.2Zm-2.6-5.19 3.9 3.89L22 3.89 18.11 0 2.58 15.5Zm12.95-2.55-1.3 1.3.64.65 1.3-1.3 1.3 1.3-1.94 1.94.65.65 1.94-1.94 1.3 1.27-2.59 2.59-3.88-3.88-1.3 1.3L16.82 22 22 16.81l-5.17-5.17-1.32 1.31Zm-9.07-3.9L2.6 5.19l2.6-2.6 1.25 1.29L4.5 5.82l.65.65 1.94-1.94 1.3 1.3-1.3 1.3.65.64 1.3-1.3 1.31-1.3L5.19 0 0 5.19l5.15 5.15 1.3-1.3Z"
        transform="translate(9 8.26)"
      />
    </svg>
  );
};

export default IconDesign;
