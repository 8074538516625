import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import style from './product-compare.mod.scss';

const ProductCompare = ({ product, comparableProducts, specList }) => {
  const itemsList = [product, ...comparableProducts];

  const contactPage = useStaticQuery(graphql`
    {
      datoCmsContactPage {
        originalId
      }
    }
  `);

  return (
    <div className={style.compare} id={'product-compare'}>
      <Container width="medium" pt={2} pb={2}>
        <div className={style.compare__content}>
          <h2 className={style.compare__heading}>Product Compare</h2>
          <div className={style.compare__table__wrapper}>
            <table className={style.compare__table}>
              <thead>
                <tr className={style.compare__tableRow}>
                  <th></th>
                  {itemsList.map((item) => (
                    <th>
                      <div className={style.compare__image}>
                        <GatsbyImage
                          image={item.featureImage.gatsbyImageData}
                          alt={item.featureImage.alt || ''}
                        />
                      </div>
                      <h5>{item.title}</h5>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {specList.map((spec) => (
                  <tr className={style.compare__tableRow}>
                    <td>{spec.titleTranslation || spec.title}</td>
                    {itemsList.map((item) => {
                      const getSpec = item.productFeatureList.find(
                        (ref) => ref.specReference.title === spec.title
                      );
                      return <td>{getSpec?.value && getSpec.value}</td>;
                    })}
                  </tr>
                ))}
                <tr className={style.compare__tableRow}>
                  <td></td>
                  <td className={style.compare__button}>
                    <Button
                      recordId={contactPage.datoCmsContactPage.originalId}
                      size={'small'}
                    >
                      {'Contact us'}
                    </Button>
                  </td>
                  {itemsList.slice(1).map((item) => (
                    <td className={style.compare__button}>
                      <Button size={'small'} recordId={item.id}>
                        {'Find out more'}
                      </Button>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductCompare;
