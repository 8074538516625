import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../Layout/SharedStyles/Container';
import style from './product-intro.mod.scss';

const ProductIntro = ({ image, text, list }) => {
  return (
    <Container width="medium" pt={2} pb={2}>
      <div className={style.intro}>
        <div className={style.intro__content}>
          <p className={style.intro__description}>{text}</p>
          <ul className={style.intro__list}>
            {list.map(({ specReference, value }) => (
              <li className={style.intro__item} key={specReference.title}>
                <span className={style.intro__item__heading}>
                  {specReference.titleTranslation
                    ? specReference.titleTranslation
                    : specReference.title}
                </span>
                <span>{value}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={style.intro__image}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
        </div>
      </div>
    </Container>
  );
};

export default ProductIntro;
