import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import style from './swatch.mod.scss';

const Swatch = ({ onChange, swatch, name, isChecked, disabled }) => {
  const modifiers = [];
  isChecked && modifiers.push(`${style.swatch}--checked`);
  disabled && modifiers.push(`${style.swatch}--disabled`);
  return (
    <div className={[style.swatch, ...modifiers].join(' ')}>
      <GatsbyImage
        className={style.swatch__image}
        image={swatch.gatsbyImageData}
        alt={swatch.alt}
      />
      <div className={style.swatch__name}>{name}</div>
    </div>
  );
};

export default Swatch;
