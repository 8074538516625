import React from 'react';

const IconLighting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height={20}
      width={20}
    >
      <circle id="Oval" cx="20" cy="20" r="20" fill="#3095b4" />
      <path
        id="iconmonstr-light-bulb-2"
        fill="#fff"
        d="M20.17 7.3c0 4.51-4.07 7.56-4.07 11.12h-2.15c0-2.25 1.08-4.14 2.13-5.97C17.07 10.73 18 9.1 18 7.3a5.12 5.12 0 0 0-5.42-5.13A5.12 5.12 0 0 0 7.17 7.3c0 1.8.93 3.43 1.92 5.15 1.05 1.83 2.13 3.72 2.13 5.97H9.06C9.06 14.86 5 11.8 5 7.3 5 2.59 8.79 0 12.58 0s7.59 2.6 7.59 7.3Zm-4.34 12.74a.54.54 0 0 1-.54.54H9.88a.54.54 0 1 1 0-1.08h5.41a.54.54 0 0 1 .54.54Zm0 2.17a.54.54 0 0 1-.54.54H9.88a.54.54 0 1 1 0-1.08h5.41a.54.54 0 0 1 .54.54ZM14 25.63a1.09 1.09 0 0 1-.82.37H12a1.09 1.09 0 0 1-.81-.37l-1.58-1.8h5.96Z"
        transform="translate(8 6.76)"
      />
    </svg>
  );
};

export default IconLighting;
