import React from 'react';

const IconSwirlPrimary = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.406 112.42">
      <g id="Layer_2" data-name="Layer 2" transform="translate(-.001 .029)">
        <g
          id="Layer_1"
          fill="#005c84"
          data-name="Layer 1"
          transform="translate(.001 -.029)"
        >
          <path
            id="Path_157"
            d="M145.84 166.081c3.434.541 6.674 1.118 9.932 1.554 9.606 1.286 18.847-.709 28.026-3.24a8.611 8.611 0 0 1 3.584-.44 3.587 3.587 0 0 1 3.355 3.751 3.972 3.972 0 0 1-3.786 3.887c-12.341 2.284-29.216 1.228-41.111-5.512Z"
            data-name="Path 157"
            transform="translate(-81.635 -91.728)"
          />
          <path
            id="Path_158"
            d="M52.406 78.074c-3.434-.542-6.674-1.118-9.932-1.554-9.606-1.294-18.847.713-28.026 3.24a8.611 8.611 0 0 1-3.584.44 3.587 3.587 0 0 1-3.354-3.751 3.972 3.972 0 0 1 3.79-3.887c12.336-2.285 29.211-1.229 41.106 5.512Z"
            data-name="Path 158"
            transform="translate(-4.204 -39.993)"
          />
          <path
            id="Path_159"
            d="M78.074 145.84c-.542 3.434-1.118 6.674-1.554 9.932-1.286 9.606.713 18.847 3.24 28.026a8.611 8.611 0 0 1 .44 3.584 3.587 3.587 0 0 1-3.751 3.355 3.972 3.972 0 0 1-3.887-3.786c-2.285-12.341-1.229-29.216 5.512-41.111Z"
            data-name="Path 159"
            transform="translate(-40.006 -81.622)"
          />
          <path
            id="Path_160"
            d="M166.081 52.406c.541-3.434 1.118-6.674 1.554-9.932 1.286-9.606-.709-18.847-3.24-28.026a8.611 8.611 0 0 1-.44-3.584 3.587 3.587 0 0 1 3.751-3.355 3.972 3.972 0 0 1 3.887 3.786c2.284 12.341 1.228 29.216-5.512 41.111Z"
            data-name="Path 160"
            transform="translate(-91.742 -4.191)"
          />
          <path
            id="Path_161"
            d="M172.43 83.381c2.2-2.7 4.306-5.217 6.309-7.823 5.917-7.678 8.8-16.676 11.209-25.891a8.725 8.725 0 0 1 1.409-3.328 3.59 3.59 0 0 1 4.926-1.03 3.971 3.971 0 0 1 1.47 5.226c-4.183 11.829-13.538 25.917-25.323 32.846Z"
            data-name="Path 161"
            transform="translate(-96.519 -25.027)"
          />
          <path
            id="Path_162"
            d="M50.184 122.81c-2.2 2.7-4.306 5.217-6.309 7.823-5.917 7.678-8.8 16.676-11.209 25.891a8.726 8.726 0 0 1-1.409 3.328 3.59 3.59 0 0 1-4.926 1.03 3.975 3.975 0 0 1-1.47-5.226c4.182-11.829 13.539-25.917 25.323-32.846Z"
            data-name="Path 162"
            transform="translate(-13.688 -68.731)"
          />
          <path
            id="Path_163"
            d="M122.81 172.43c2.7 2.2 5.217 4.306 7.823 6.309 7.678 5.917 16.676 8.8 25.891 11.209a8.725 8.725 0 0 1 3.328 1.409 3.59 3.59 0 0 1 1.03 4.926 3.971 3.971 0 0 1-5.226 1.47c-11.829-4.183-25.917-13.538-32.846-25.323Z"
            data-name="Path 163"
            transform="translate(-68.744 -96.506)"
          />
          <path
            id="Path_164"
            d="M83.375 50.184c-2.7-2.2-5.217-4.306-7.823-6.309-7.678-5.917-16.676-8.8-25.891-11.209a8.726 8.726 0 0 1-3.328-1.409 3.614 3.614 0 0 1-1.048-4.926 3.975 3.975 0 0 1 5.226-1.47C62.358 29.043 76.446 38.4 83.375 50.184Z"
            data-name="Path 164"
            transform="translate(-25.035 -13.675)"
          />
          <path
            id="Path_165"
            d="M164 125.038c3.245-1.246 6.34-2.364 9.377-3.619 8.963-3.689 15.968-10.038 22.65-16.817a8.968 8.968 0 0 1 2.884-2.2 3.59 3.59 0 0 1 4.781 1.572 4.013 4.013 0 0 1-1.321 5.283c-9.553 8.151-24.701 15.671-38.371 15.781Z"
            data-name="Path 165"
            transform="translate(-91.801 -57.118)"
          />
          <path
            id="Path_166"
            d="M40.208 101.08c-3.245 1.246-6.339 2.364-9.377 3.619-8.963 3.689-15.968 10.038-22.651 16.817A8.9 8.9 0 0 1 5.3 123.7a3.59 3.59 0 0 1-4.781-1.572 3.981 3.981 0 0 1 1.321-5.261c9.55-8.158 24.698-15.681 38.368-15.787Z"
            data-name="Path 166"
            transform="translate(-.001 -56.568)"
          />
          <path
            id="Path_167"
            d="M101.08 164c1.246 3.245 2.364 6.34 3.619 9.377 3.689 8.963 10.038 15.968 16.817 22.65a8.9 8.9 0 0 1 2.179 2.884 3.59 3.59 0 0 1-1.572 4.781 3.981 3.981 0 0 1-5.261-1.321c-8.153-9.553-15.676-24.701-15.782-38.371Z"
            data-name="Path 167"
            transform="translate(-56.581 -91.787)"
          />
          <path
            id="Path_168"
            d="M125.021 40.191c-1.246-3.227-2.364-6.34-3.632-9.39-3.689-8.963-10.038-15.968-16.817-22.65a8.967 8.967 0 0 1-2.2-2.884 3.59 3.59 0 0 1 1.57-4.781 4.013 4.013 0 0 1 5.283 1.321c8.167 9.566 15.686 24.715 15.796 38.384Z"
            data-name="Path 168"
            transform="translate(-57.114 .029)"
          />
          <path
            id="Path_169"
            d="M143.82 153.04c2.641.225 5.151.484 7.656.634 7.4.44 14.33-1.594 21.184-4.037a6.713 6.713 0 0 1 2.712-.537 2.747 2.747 0 0 1 2.769 2.672 3.037 3.037 0 0 1-2.672 3.174c-9.28 2.434-22.206 2.571-31.649-1.906Z"
            data-name="Path 169"
            transform="translate(-80.505 -83.446)"
          />
          <path
            id="Path_170"
            d="M67.861 92.717c-2.641-.225-5.151-.484-7.656-.634-7.4-.44-14.33 1.594-21.184 4.033a6.714 6.714 0 0 1-2.712.537 2.747 2.747 0 0 1-2.769-2.673 3.037 3.037 0 0 1 2.672-3.174c9.28-2.43 22.206-2.567 31.649 1.911Z"
            data-name="Path 170"
            transform="translate(-18.77 -49.877)"
          />
          <path
            id="Path_171"
            d="M92.718 143.82c-.225 2.641-.484 5.151-.634 7.656-.44 7.4 1.594 14.33 4.037 21.184a6.714 6.714 0 0 1 .537 2.712 2.747 2.747 0 0 1-2.672 2.769 3.037 3.037 0 0 1-3.174-2.672c-2.435-9.28-2.572-22.206 1.906-31.649Z"
            data-name="Path 171"
            transform="translate(-49.891 -80.492)"
          />
          <path
            id="Path_172"
            d="M153.04 67.861c.225-2.641.484-5.151.634-7.656.44-7.4-1.594-14.33-4.037-21.184a6.713 6.713 0 0 1-.537-2.712 2.747 2.747 0 0 1 2.672-2.769 3.037 3.037 0 0 1 3.174 2.672c2.434 9.28 2.571 22.206-1.906 31.649Z"
            data-name="Path 172"
            transform="translate(-83.46 -18.757)"
          />
          <path
            id="Path_173"
            d="M162.06 89.844c1.514-2.2 2.994-4.218 4.376-6.317 4.081-6.163 5.785-13.207 7.1-20.366a6.731 6.731 0 0 1 .881-2.615 2.747 2.747 0 0 1 3.7-1.061 3.045 3.045 0 0 1 1.413 3.9c-2.53 9.254-8.872 20.515-17.47 26.459Z"
            data-name="Path 173"
            transform="translate(-90.715 -33.097)"
          />
          <path
            id="Path_174"
            d="M70.8 126.46c-1.514 2.2-2.994 4.218-4.376 6.318-4.081 6.185-5.785 13.207-7.1 20.366a6.661 6.661 0 0 1-.88 2.615 2.747 2.747 0 0 1-3.7 1.061 3.044 3.044 0 0 1-1.413-3.9c2.523-9.255 8.869-20.499 17.469-26.46Z"
            data-name="Path 174"
            transform="translate(-29.734 -70.774)"
          />
          <path
            id="Path_175"
            d="M126.46 162.06c2.2 1.514 4.218 2.994 6.318 4.376 6.185 4.081 13.207 5.785 20.366 7.1a6.732 6.732 0 0 1 2.615.88 2.747 2.747 0 0 1 1.061 3.7 3.045 3.045 0 0 1-3.9 1.413c-9.255-2.529-20.499-8.871-26.46-17.469Z"
            data-name="Path 175"
            transform="translate(-70.787 -90.701)"
          />
          <path
            id="Path_176"
            d="M89.844 70.8c-2.2-1.514-4.218-2.994-6.317-4.376-6.163-4.081-13.207-5.785-20.366-7.1a6.661 6.661 0 0 1-2.615-.88 2.747 2.747 0 0 1-1.061-3.7 3.044 3.044 0 0 1 3.9-1.413C72.639 55.854 83.9 62.2 89.844 70.8Z"
            data-name="Path 176"
            transform="translate(-33.11 -29.721)"
          />
          <path
            id="Path_177"
            d="M156.85 121.043c2.4-1.131 4.7-2.157 6.947-3.28 6.63-3.315 11.614-8.545 16.329-14.088a6.7 6.7 0 0 1 2.078-1.823 2.746 2.746 0 0 1 3.733.933 3.043 3.043 0 0 1-.726 4.085c-6.82 6.73-17.945 13.337-28.361 14.173Z"
            data-name="Path 177"
            transform="translate(-87.798 -56.799)"
          />
          <path
            id="Path_178"
            d="M60.861 109.43c-2.413 1.131-4.7 2.157-6.947 3.28-6.63 3.315-11.614 8.545-16.329 14.088a6.7 6.7 0 0 1-2.078 1.814 2.746 2.746 0 0 1-3.733-.933 3.043 3.043 0 0 1 .726-4.085c6.82-6.737 17.945-13.319 28.361-14.164Z"
            data-name="Path 178"
            transform="translate(-17.507 -61.241)"
          />
          <path
            id="Path_179"
            d="M109.43 156.85c1.131 2.4 2.157 4.7 3.28 6.947 3.315 6.63 8.545 11.614 14.088 16.329a6.7 6.7 0 0 1 1.823 2.078 2.746 2.746 0 0 1-.933 3.733 3.043 3.043 0 0 1-4.085-.726c-6.746-6.82-13.328-17.945-14.173-28.361Z"
            data-name="Path 179"
            transform="translate(-61.255 -87.785)"
          />
          <path
            id="Path_180"
            d="M121.043 60.861c-1.131-2.413-2.157-4.7-3.28-6.947-3.315-6.63-8.545-11.614-14.088-16.329a6.7 6.7 0 0 1-1.823-2.078 2.746 2.746 0 0 1 .933-3.733 3.043 3.043 0 0 1 4.085.726c6.73 6.82 13.337 17.945 14.173 28.361Z"
            data-name="Path 180"
            transform="translate(-56.812 -17.494)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconSwirlPrimary;
