import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import style from './product-overview.mod.scss';

const ProductOverview = ({ heading, content, image }) => {
  const contactPage = useStaticQuery(graphql`
    {
      datoCmsContactPage {
        originalId
      }
    }
  `);

  return (
    <div className={style.overview} id={'product-overview'}>
      <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ''} />
      <div className={style.overview__overlay}>
        <div className={style.overview__content}>
          <h2 className={style.overview__heading}>{heading}</h2>
          <p className={style.overview__description}>{content}</p>
          <div className={style.overview__buttons}>
            <Button recordId={contactPage.datoCmsContactPage.originalId}>
              Contact a spa expert
            </Button>
            <Button recordId={contactPage.datoCmsContactPage.originalId}>
              book a wet test
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductOverview;
