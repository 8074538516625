import React from 'react';

const IconWater = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height={20}
      width={20}
    >
      <circle id="Oval" cx="20" cy="20" r="20" fill="#3095b4" />
      <path
        id="iconmonstr-drop-10"
        fill="#fff"
        d="M15.1 13c1.35 3.88.47 7.2-2.17 8.55 3.86.2 6.28-4.17 2.17-8.55Zm-2.43-6.98c2.95 5.62 6.5 7.28 6.5 11.4a6.5 6.5 0 0 1-13 0c0-4.12 3.54-5.78 6.5-11.4Zm0-6.02C10.14 9.7 4 11.04 4 17.41a8.67 8.67 0 0 0 17.33 0c0-6.37-6.14-7.71-8.66-17.41Z"
        transform="translate(7 5.76)"
      />
    </svg>
  );
};

export default IconWater;
