import React from 'react';
import Container from '../Layout/SharedStyles/Container';
import style from './product-features.mod.scss';
import IconSwirlPrimary from '../Layout/Icons/IconSwirlPrimary';
import IconLogoVortex from '../Layout/Icons/IconLogoVortex';

const ProductFeatures = ({ heading, brand }) => {
  const getIcon = () => {
    if (brand === 'Vortex') {
      return <IconLogoVortex height={70} width={150} />;
    } else {
      return <IconSwirlPrimary />;
    }
  };

  const classes = [style.features];
  brand === 'Vortex' && classes.push(style.features__vortex);

  return (
    <Container width="medium" pt={3} pb={3}>
      <div className={[...classes].join(' ')}>
        {getIcon()}
        <h2>{heading}</h2>
      </div>
    </Container>
  );
};

export default ProductFeatures;
