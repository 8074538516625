import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import { PageIntro } from '../components/Layout/PageIntro/PageIntro';
import ModuleArea from '../components/ModularContent/ModuleArea';
import ProductIntro from '../components/Product/ProductIntro';
import ProductColors from '../components/Product/ProductColors';
import ProductCompare from '../components/Product/ProductCompare';
import ProductNav from '../components/Product/ProductNav';
import ProductOverview from '../components/Product/ProductOverview';
import ProductSpecs from '../components/Product/ProductSpecs';
import ProductFeatures from '../components/Product/ProductFeatures';

const ProductPages = ({
  data: {
    allDatoCmsProductSpecItem,
    datoCmsProduct: {
      title,
      brand,
      collection,
      featureImage,
      featuresHeading,
      description,
      productFeatureList,
      modularContent,
      specWidth,
      specLength,
      specPdfLink,
      warrantyPdfLink,
      specItems,
      specsImage,
      overviewImage,
      overviewHeading,
      overviewContent,
      comparableProducts,
      comparableSpecs,
      compareImage,
      productVariants,
      id,
      metaTags,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: pageContext.collection.title, id: pageContext.collection.id },
    { title: title, id: id },
  ];

  const hasVariants = productVariants.length > 0;

  return (
    <PageWrapper pageData={pageContext} metaTags={metaTags}>
      <PageIntro title={title} breadcrumbs={breadcrumbs} button />
      <ProductIntro
        image={featureImage}
        text={description}
        list={productFeatureList}
      />
      <ProductNav noVariants={!hasVariants} />
      <ProductOverview
        heading={overviewHeading}
        content={overviewContent}
        image={overviewImage}
      />
      {hasVariants && <ProductColors variants={productVariants} />}
      <ProductFeatures heading={featuresHeading} brand={brand} />
      {modularContent?.length > 0 && (
        <div id={'product-design'}>
          <ModuleArea blocks={modularContent} />
        </div>
      )}
      <ProductSpecs
        image={specsImage}
        length={specLength}
        width={specWidth}
        pdfLink={specPdfLink}
        warrantyPdfLink={warrantyPdfLink}
        items={specItems}
      />
      {comparableProducts.length > 0 && (
        <ProductCompare
          product={{
            title: title,
            productFeatureList: productFeatureList,
            featureImage: compareImage,
          }}
          comparableProducts={comparableProducts}
          specList={comparableSpecs}
        />
      )}
    </PageWrapper>
  );
};

export default ProductPages;

export const query = graphql`
  query ProductPages($locale: String!, $id: String!) {
    allDatoCmsProductSpecItem(filter: { locale: { eq: $locale } }) {
      nodes {
        position
        title
        titleTranslation
      }
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
    datoCmsProduct(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      metaTags {
        title
        description
        image {
          url
        }
      }
      title
      brand
      id: originalId
      collection {
        id: originalId
        title
      }
      productFeatureList {
        specReference {
          title
          titleTranslation
        }
        value
      }
      comparableSpecs {
        title
        titleTranslation
      }
      comparableProducts {
        title
        id: originalId
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "250"
              fit: "fill"
              fill: "solid"
              w: "400"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
        productFeatureList {
          value
          specReference {
            title
            titleTranslation
          }
        }
      }
      specWidth
      specPdfLink
      warrantyPdfLink
      specLength
      specItems {
        details {
          title
          value
        }
        heading
        icon
      }
      specsImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "450", w: "680", q: 60 }
        )
      }
      featuresHeading
      overviewImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "730", fit: "crop", w: "1440", q: 60 }
        )
      }
      overviewHeading
      overviewContent
      description
      featureImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "450", w: "680", q: 60 }
        )
      }
      compareImage: featureImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", w: "400", q: 60 }
        )
      }
      productVariants {
        internalTitle
        sceneId
        cabinetProductId
        cabinetVariationId
        shellProductId
        shellVariationId
        arProductId
        arVariationId
        cabinetColor {
          title
          swatch {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "485"
                fit: "cover"
                w: "820"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
        shellColor {
          title
          swatch {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "485"
                fit: "cover"
                w: "820"
                q: 60
                auto: "format"
              }
            )
            alt
          }
        }
        render {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fit: "cover", w: "1200", q: 60 }
          )
          alt
        }
      }
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentWithImageBlock {
          ...ContentWithImage
        }
        ... on DatoCmsSliderFullWidthBlock {
          ...SliderFullWidth
        }
      }
    }
  }
`;
