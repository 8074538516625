import React from 'react';

const IconPower = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height={20}
      width={20}
    >
      <circle id="Oval" cx="20" cy="20" r="20" fill="#3095b4" />
      <path
        id="iconmonstr-power-on-off-7"
        fill="#fff"
        d="M11.33 14.86a1.52 1.52 0 0 1 .6-1.73 1.6 1.6 0 0 1 .93-.31 1.47 1.47 0 0 1 .9.3A1.58 1.58 0 0 0 12.24 12a1.56 1.56 0 0 0-.91.3 1.58 1.58 0 0 0 0 2.56Zm2.64 6.43A17.2 17.2 0 0 1 19.35 24L24 20.68c-1.2-1.7-.88-2.87-2.08-4.56-.46-.65-.88-1.08-1.56-1.08a2.97 2.97 0 0 0-.57.07l.42.6a.33.33 0 0 1-.54.38l-.38-.53a.58.58 0 0 0-.46-.24 5.73 5.73 0 0 0-.98.2l.51.72a.33.33 0 1 1-.54.38l-.44-.62a.57.57 0 0 0-.47-.24 5.42 5.42 0 0 0-1.01.24l.54.76a.33.33 0 0 1-.53.38l-2.2-3.07a.9.9 0 1 0-1.48 1.05l3.25 4.55c-.87-.33-2.22-.47-2.47.43-.15.54.29.97.95 1.2ZM9 6h4v4.09a3.65 3.65 0 0 0-.76-.09A3.52 3.52 0 0 0 9 12.12V6Zm2.56 15.97L11 22a11.02 11.02 0 1 1 10.74-8.66 3.3 3.3 0 0 0-1.38-.3 3.73 3.73 0 0 0-.62.06A9 9 0 1 0 11 20h.01a2.78 2.78 0 0 0 .55 1.97Z"
        transform="translate(8 7.76)"
      />
    </svg>
  );
};

export default IconPower;
